:root {
  --primary: #70c057;
  --primary-50: #70c057b3;
  --secondary: #f2a640;
  --info: #00000091;
  --dark: #343a40;
  --dark-50: #707070;
  --white: #fff;
  --light: #adb5bd;
}

* {
  box-sizing: border-box;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
}

.general-body {
  height: 100%;
}

.stars {
  display: flex;
  justify-content: center;
}

.stars button {
  /* margin-inline: 2vw; */
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;
}

.stars img {
  filter: grayscale(100%);
}

.btn.btn-light-primary {
  font-weight: 600 !important;
  color: var(--kt-primary);
  border-color: var(--kt-primary-light);
  background-color: var(--kt-primary-light);
}
.btn-check:checked + .btn.btn-light-primary,
.btn-check:active + .btn.btn-light-primary,
.btn.btn-light-primary:focus:not(.btn-active),
.btn.btn-light-primary:hover:not(.btn-active),
.btn.btn-light-primary:active:not(.btn-active),
.btn.btn-light-primary.active,
.btn.btn-light-primary.show,
.show > .btn.btn-light-primary {
  color: var(--kt-primary-inverse);
  border-color: var(--kt-primary);
  background-color: var(--kt-primary) !important;
}
.btn-primary {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  font-weight: 600 !important;
  border: 1px solid var(--primary) !important;
  padding: 0.65rem 0.75rem !important;
  text-transform: uppercase;
  transition: ease-in-out 0.5s !important;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--secondary) !important;
  border: 1px solid var(--secondary) !important;
}
.form-control {
  font-size: 1.3rem !important;
}

.form-control:focus {
  border: 1px solid var(--secondary) !important;
  color: var(--gray) !important;
  box-shadow: none !important;
}

.footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.footer li {
  display: inline;
}

.footer button {
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;
}

.feedback-header {
  text-align: center;
  font-size: 3em;
  color: #eda75d;
}

.question {
  text-align: center;
  color: #63a04b;
}

.text2 {
  text-align: center;
  margin-top: 5vh;
  font-size: 2em;
  color: rgb(99, 160, 75);
}

.loader-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  animation: 1s linear infinite spinner;
  animation-play-state: inherit;
  border: solid 4px #eda75d;
  border-bottom-color: #63a04b;
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

@keyframes spinner {
  0% {
    transform: translate3d(0%, 0%, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0%, 0%, 0) rotate(360deg);
  }
}

.answer-button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #f5f8fa;
  color: #181C32;
  border: none;
  border-radius: 5px;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.answer-button-checked {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  /* color: white; */
  /* background-color: rgba(112, 192, 87, 0.7019607843); */
  color: rgba(112, 192, 87, 0.7019607843);
  background-color: #dfffd5;
  border: none;
  border-radius: 5px;
  text-align: left;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.answer-button:hover {
  /* background-color: rgba(112, 192, 87, 0.7019607843); */
  color: rgba(112, 192, 87, 0.7019607843);
  background-color: #dfffd5;
}

.form-check-input {
  margin-top: 0.25rem !important;
}

.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #70c057;
}
.ant-slider:hover .ant-slider-handle::after {
  box-shadow: 0 0 0 2px #70c057;
}
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 2.5px #70c057;
}
.ant-slider .ant-slider-dot-active {
  border-color: #70c057;
}
.ant-slider .ant-slider-dot {
  width: 12px;
  height: 12px;
  margin-top: -3px;
}
.ant-slider:hover .ant-slider-dot-active {
  border-color: #70c057;
}
.ant-slider .ant-slider-handle:active::before {
  outline: 6px solid rgba(134, 170, 117, 0.2);
}
.ant-slider .ant-slider-handle:active::after {
  outline: 6px solid rgba(134, 170, 117, 0.2);
}
.ant-slider .ant-slider-handle:hover::after {
  box-shadow: 0 0 0 2.5px #70c057;
  outline: 6px solid rgba(134, 170, 117, 0.2);
}
